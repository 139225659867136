import React from "react";

const config = {
  "mta-sts.hospitality-ut.com":
  `version: STSv1
mode: testing
mx: alt4.aspmx.l.google.com
mx: alt2.aspmx.l.google.com
mx: alt3.aspmx.l.google.com
mx: aspmx.l.google.com
mx: alt1.aspmx.l.google.com
max_age: 604800`
}

const hostname = "mta-sts.hospitality-ut.com"

function App() {
  return (
      <div style={{margin: 0, padding:0}}>
        <p style={{margin: 0, padding:0}}>version: STSv1</p>
        <p style={{margin: 0, padding:0}}>mode: testing</p>
        <p style={{margin: 0, padding:0}}>mx: alt4.aspmx.l.google.com</p>
        <p style={{margin: 0, padding:0}}>mx: alt2.aspmx.l.google.com</p>
        <p style={{margin: 0, padding:0}}>mx: alt3.aspmx.l.google.com</p>
        <p style={{margin: 0, padding:0}}>mx: aspmx.l.google.com</p>
        <p style={{margin: 0, padding:0}}>mx: alt1.aspmx.l.google.com</p>
        <p style={{margin: 0, padding:0}}>max_age: 604800</p>
      </div>
  );
}

export default App;
